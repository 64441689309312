import CircularProgress from "@mui/material/CircularProgress";
import { Grid, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
const Loading = ({ children }) => {
  const { isLoading } = useSelector(({ loading }) => loading);
  return (
    <Box style={{ position: "relative" }}>
      {isLoading ? (
        <Box
          style={{
            position: "absolute",
            zIndex: 9999,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            background: "#00000042",
          }}
        >
          <CircularProgress color="secondary" />
        </Box>
      ) : null}
      {children}
    </Box>
  );
};
export default Loading;
