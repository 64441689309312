import { useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Box } from "@mui/material";

const ProfileLayout = (props) => {
  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <Box
        component="main"
        sx={{
          width: "calc(100% - 260px)",
          flexGrow: 1,
        }}
      >
        <Box
          sx={{
            position: "relative",
            minHeight: "calc(100vh - 110px)",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box style={{ height: "100%" }}>
            <Outlet />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ProfileLayout;
