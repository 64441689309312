import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// material-ui
import { useTheme } from "@mui/material/styles";
import { useMediaQuery, Box, TextField } from "@mui/material";

import { useNavigate, useParams } from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
import AccountCircle from "@mui/icons-material/AccountCircle";
import ArrowBack from "components/ArrowBack";
// ==============================|| MAIN LAYOUT ||============================== //

const SearchLayout = (props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const location = useLocation();
  const [text, setText] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);
  let navigate = useNavigate();

  const onChangeSearchText = (e) => {
    setText(e.target.value);
    console.log(e.target.value);
  };
  useEffect(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    setSearchTimeout(
      setTimeout(() => {
        console.log("gg");
      }, 1000)
    );

    return () => clearTimeout(searchTimeout);
  }, [text]);
  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <Box
        component="main"
        sx={{
          width: "calc(100% - 260px)",
          flexGrow: 1,
        }}
      >
        <Box
          sx={{
            position: "relative",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box style={{ height: "100%" }}>
            <Box
              style={{
                height: "60px",
                alignItems: "center",
                paddingLeft: 10,
                paddingRight: 10,
                display: "flex",
                // width: "100%"
              }}
            >
              <ArrowBack color="primary" />
              <Box style={{ width: "100%" }}>
                <TextField
                  id="filled-search"
                  // label="Search field"
                  type="search"
                  variant="filled"
                  style={{ width: "100%", height: "40px" }}
                  sx={{
                    "& .MuiInputBase-root": {
                      borderRadius: "20px",
                      height: "40px",
                    },
                    "& .MuiInputBase-root::before": {
                      borderBottom: "none !important",
                    },
                    "& .MuiInputBase-root::after": {
                      borderBottom: "none !important",
                    },
                  }}
                  // InputProps={{
                  //   // style: { height: "30px" }
                  //   // sx: {
                  //   //   borderRadius: "20px",
                  //   //   padding: "10px",
                  //   // },
                  //   startAdornment: (
                  //     <InputAdornment position="start">
                  //       <AccountCircle />
                  //     </InputAdornment>
                  //   ),
                  // }}
                  onChange={onChangeSearchText}
                />
              </Box>
            </Box>
            <Box style={{ height: "calc(100% - 60px)" }}>
              <Outlet context={[text]} />
            </Box>
          </Box>
          {/* <Footer /> */}
        </Box>
      </Box>
    </Box>
  );
};

export default SearchLayout;
