// ==============================|| OVERRIDES - ICON BUTTON ||============================== //

export default function Icon(theme) {
    return {
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    "&.MuiSvgIcon-colorPrimary": {
                        color: "#677080"
                    }
                },

            }
        }
    };
}
