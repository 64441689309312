import { lazy } from "react";

// project import
import MainLayout from "layout/MainLayout";
import ProfileLayout from "layout/ProfileLayout";
import NotificationLayout from "layout/NotificationLayout";
import MyCouponLayout from "layout/MyCouponLayout";
import SearchLayout from "layout/SearchLayout";

import Loadable from "components/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import Guard from "layout/Guard";
// pages routing
// import Home from "pages/home";
const Home = Loadable(lazy(() => import("pages/home")));
// import Profile from "pages/Profile/profile";
const Profile = Loadable(lazy(() => import("pages/Profile/profile")));
// import PrivacyPolicy from "pages/Profile/privacyPolicy";
const PrivacyPolicy = Loadable(
  lazy(() => import("pages/Profile/privacyPolicy"))
);
// import TermsAndConditions from "pages/Profile/termsAndConditions";
const TermsAndConditions = Loadable(
  lazy(() => import("pages/Profile/termsAndConditions"))
);
// import Library from "pages/Library/library";
const Library = Loadable(lazy(() => import("pages/Library/library")));
// import PrivilegesList from "pages/Library/Privileges/PrivilegesList";
const PrivilegesList = Loadable(
  lazy(() => import("pages/Library/Privileges/PrivilegesList"))
);
// import PrivilegesInfo from "pages/Library/Privileges/PrivilegesInfo";
const PrivilegesInfo = Loadable(
  lazy(() => import("pages/Library/Privileges/PrivilegesInfo"))
);
// import ServiceList from "pages/Library/Service/ServiceList";
const ServiceList = Loadable(
  lazy(() => import("pages/Library/Service/ServiceList"))
);
// import ServiceInfo from "pages/Library/Service/ServiceInfo";
const ServiceInfo = Loadable(
  lazy(() => import("pages/Library/Service/ServiceInfo"))
);
// import MyCoupon from "pages/MyCoupon/myCoupon";
// import MyCoupoInfo from "pages/MyCoupon/myCoupoInfo";
// import ViewCode from "pages/MyCoupon/ViewCode/ViewCode";
const MyCoupon = Loadable(lazy(() => import("pages/MyCoupon/myCoupon")));
const MyCoupoInfo = Loadable(lazy(() => import("pages/MyCoupon/myCoupoInfo")));
const ViewCode = Loadable(
  lazy(() => import("pages/MyCoupon/ViewCode/ViewCode"))
);
// import Notification from "pages/Notification/notification";
// import NotificationInfo from "pages/Notification/info";
// import Search from "pages/Search";
const Notification = Loadable(
  lazy(() => import("pages/Notification/notification"))
);
const NotificationInfo = Loadable(
  lazy(() => import("pages/Notification/info"))
);
const Search = Loadable(lazy(() => import("pages/Search")));

const MainRoutes = {
  path: "/",
  element: <Guard />,
  children: [
    {
      path: "/main",
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "home",
          element: <Home />,
        },
      ],
    },
    {
      path: "/profile",
      element: (
        <AuthGuard>
          <ProfileLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: <Profile />,
        },
        {
          path: "privacyPolicy",
          element: <PrivacyPolicy />,
        },
        {
          path: "termsAndConditions",
          element: <TermsAndConditions />,
        },
      ],
    },
    {
      path: "/Library",
      element: <MainLayout />,
      children: [
        {
          path: "",
          element: (
            <AuthGuard>
              <Library />
            </AuthGuard>
          ),
        },
        {
          path: "privileges/:id",
          element: (
            <AuthGuard>
              <PrivilegesList />
            </AuthGuard>
          ),
        },
        {
          path: "privilegesInfo/:id",
          element: <PrivilegesInfo />,
        },
        {
          path: "service/:id",
          element: (
            <AuthGuard>
              <ServiceList />
            </AuthGuard>
          ),
        },
        {
          path: "serviceInfo/:id",
          element: <ServiceInfo />,
        },
      ],
    },
    {
      path: "/MyCoupon",
      element: (
        <AuthGuard>
          <MyCouponLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: <MyCoupon />,
        },
        {
          path: "info/:id",
          element: <MyCoupoInfo />,
        },
        {
          path: "viewcode/:id",
          element: <ViewCode />,
        },
      ],
    },
    {
      path: "/Notification",
      element: (
        // <AuthGuard>
        <NotificationLayout />
        // </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: <Notification />,
        },
        {
          path: "info/:id",
          element: <NotificationInfo />,
        },
      ],
    },
    {
      path: "/Search",
      element: (
        // <AuthGuard>
        <SearchLayout />
        // </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: <Search />,
        },
      ],
    },
  ],
};

export default MainRoutes;
