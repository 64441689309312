import PropTypes from "prop-types";
import { Box } from "@mui/material";

const Label = (props) => {
  const {
    text,
    id,
    name,
    color,
    size,
    fontWeight,
    style,
    mt,
    mb,
    numberOfLine,
    textAlign
  } = props;
  return (
    <Box
      mt={mt}
      mb={mb}
      id={id}
      name={name}
      style={{
        ...{
          WebkitWserSelect: "none",
          MsUserSelect: "none",
          userSelect: "none",
          fontSize: size + "px" || 16 + "px",
          color: color || "#677081",
          fontWeight: fontWeight || "",
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: numberOfLine,
          wordWrap: "break-word",
          lineHeight: "normal",
          fontFamily: "DB Heavent",
          fontStyle: "normal",
        },
        ...style,
      }}
      sx={{ textAlign: textAlign }}
    >
      {text}
    </Box>
  );
};
Label.propTypes = {
  text: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.number,
  fontWeight: PropTypes.string,
  style: PropTypes.object,
  mt: PropTypes.number,
  mb: PropTypes.number,
  numberOfLine: PropTypes.number,
};
export default Label;
