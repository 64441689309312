// action - account reducer
export const LOGIN = "@auth/LOGIN";
export const LOGOUT = "@auth/LOGOUT";
export const REGISTER = "@auth/REGISTER";
export const UPDATE_AUTH_USER = "@auth/update_auth_user";
export const FETCH_START = "fetch_start";
export const FETCH_SUCCESS = "fetch_success";
export const Loading = "Loading";
export const FETCH_PromotionTrue = "FETCH_PromotionTrue";
export const FETCH_PromotionFalse = "FETCH_PromotionFalse";
export const UnitID = "UnitID";

export const notification = "notification";

export const setLoading = (loading) => {
  return (dispatch) => {
    dispatch({
      type: Loading,
      payload: loading,
    });
  };
};
export const setnotification = (loading) => {
  return (dispatch) => {
    dispatch({
      type: notification,
      payload: loading,
    });
  };
};
export const setUnitID = (loading) => {
  return (dispatch) => {
    dispatch({
      type: UnitID,
      payload: loading,
    });
  };
};

export const setAuthUser = (user) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_AUTH_USER,
      payload: user,
    });
  };
};
