// third-party
import { createSlice } from '@reduxjs/toolkit';
import { FETCH_START, FETCH_SUCCESS } from './actions';
// initial state
const initialState = {
  loading: false,
};

// ==============================|| SLICE - MENU ||============================== //
const loader = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_START: {
      return { ...state, loading: true }
    }
    case FETCH_SUCCESS: {
      return { ...state, loading: true }
    }
    default: {
      return { ...state };
    }
  }
};


export default loader;

export const fetchSuccess = message => {
  return dispatch => {
    dispatch({
      type: FETCH_SUCCESS,
      payload: message || '',
    })
  }
}
export const fetchStart = () => {
  return dispatch => {
    dispatch({
      type: FETCH_START,
    })
  }
}
export const loaderStatus = {
  Start: fetchStart,
  Success: fetchSuccess
}
