import liff from "@line/liff";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import service from "undefined-service-web";
import App from "components";
import { useDispatch } from "react-redux";
import { setAuthUser } from "../../store/reducers/actions.js";
const GuestGuard = ({ children }) => {
  const dispatch = useDispatch();

  const initLine = async () => {
    // localStorage.setItem("MANA-LineID", 'Ub491d02abe81de801c5be056fff6bc1f');
    // const linelogin = await App.service.postHttp("auth/linelogin", {
    //   LineID: 'Ub491d02abe81de801c5be056fff6bc1f',
    // });
    // if (linelogin.data.status) {
    //   localStorage.setItem("token", linelogin.data.access_token);
    //   dispatch(setAuthUser(linelogin.data.access_token));
    // }
    await liff.init(
      { liffId: "1655307286-bJ9R4pMp" },
      () => {
        if (liff.isLoggedIn()) {
          liff
            .getProfile()
            .then(async (profile) => {
              const LineID = profile.userId;
              localStorage.setItem("MANA-LineID", LineID);
              const linelogin = await App.service.postHttp("auth/linelogin", {
                LineID: LineID,
              });
              if (linelogin.data.status) {
                // console.log(linelogin.data.access_token);
                localStorage.setItem("token", linelogin.data.access_token);
                dispatch(setAuthUser(linelogin.data.access_token));
              }
            })
            .catch((err) => console.error(err));
        } else {
          liff.login();
        }
      },
      (err) => console.error(err)
    );
  };

  const { authUser } = useSelector(({ auth }) => auth);
  const navigate = useNavigate();
  useEffect(() => {
    const LineID = localStorage.getItem("MANA-LineID");
    console.log(LineID);
    if (service.isNullOrEmpty(LineID)) {
      initLine();
    } else {
      let token = localStorage.getItem("token");
      if (!service.isNullOrEmpty(token) && !service.isNullOrEmpty(authUser)) {
        dispatch(setAuthUser(token));
        navigate("main/home", { replace: true });
      } else if (!service.isNullOrEmpty(token)) {
        dispatch(setAuthUser(token));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authUser, navigate]);

  return children;
};

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default GuestGuard;
