import { UnitID } from "./actions";
// initial state
const initialState = {
  Unit: null,
  UnitNo: null,
  UnitID: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UnitID: {
      return {
        ...state,
        Unit: action.payload.Unit,
        UnitNo: action.payload.UnitNo,
        UnitID: action.payload.UnitID,
      };
    }
    default:
      return state;
  }
};
