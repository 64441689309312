import { Button } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
export default function ButtonUC({
  label = "",
  sx,
  onClick,
  component,
  to,
  variant = "contained",
  isSelect = 0,
  type = "button",
  disableElevation,
  disabled = false,
  fullWidth,
  minWidth = "6rem",
  borderRadius,
  startIcon,
  endIcon,
  style,
  loading,
}) {
  return (
    <>
      <LoadingButton
        type={type}
        fullWidth={fullWidth}
        sx={{
          ...sx,
          minWidth,
          borderRadius,
          color: variant === "outlined" ? "undefined.primary" : "white",
          borderColor:
            variant === "outlined" ? "undefined.primary" : "undefined.primary",
          bgcolor: variant === "contained" ? "undefined.primary" : "",
          "&.MuiButton-root": {
            "&:hover": {
              // bgcolor: variant === 'contained' ? 'undefined.primary' : '',
              // borderColor: variant === 'outlined' ? 'undefined.primary' : ''
            },
          },
        }}
        disableElevation={disableElevation}
        disabled={disabled}
        onClick={onClick ? () => onClick() : null}
        component={component}
        to={to}
        variant={
          variant === "outlined"
            ? isSelect === 1
              ? "contained"
              : "outlined"
            : variant
        }
        startIcon={startIcon}
        endIcon={endIcon}
        style={style}
        loading={loading || false}
      >
        {label}
      </LoadingButton>
    </>
  );
}
