import { useNavigate } from "react-router-dom";
import App from "components";
import { Box } from "@mui/material";
import Label from "components/Label";
const BottomMenu = ({ index, isMenu }) => {
  let navigate = useNavigate();

  const Menu = (props) => {
    return (
      <Box
        style={{
          width: "33.33%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={() => {
          navigate(props.to);
        }}
      >
        <Box
          sx={{
            position: "relative",
          }}
        >
          {props.active == true ? (
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              sx={{
                left: 0,
                right: 0,
                marginTop: "-35px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "0px",
                  gap: "8px",
                  width: "60px",
                  height: "60px",
                  background: "#FFFFFF",
                  boxShadow: "0px 0px 16px rgb(0 0 0 / 10%)",
                  borderRadius: "300px",
                  flex: "none",
                  order: "0",
                  flexGrow: "0",
                }}
              >
                {props.icon}
              </Box>
            </Box>
          ) : (
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              sx={{
                left: 0,
                right: 0,
              }}
            >
              {props.icon}
            </Box>
          )}
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Label
              text={props.lalel}
              size={16}
              fontWeight="400"
              color={props.active == true ? App.color.main : App.color.gray}
            />
          </Box>
        </Box>
      </Box>
    );
  };
  return (
    <Box
      style={{
        backgroundColor: "#FFF",
        width: "100%",
        height: isMenu != false ? "110px" : "50px",
        position: "absolute",
        bottom: 0,
      }}
    >
      {isMenu != false ? (
        <Box
          style={{
            backgroundColor: "#FFF",
            width: "100%",
            height: "60px",
            display: "flex",
            alignItems: "center",
            // border: "1px solid #000",
            boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Menu
            to="/Library"
            lalel="Category"
            active={index == 1}
            icon={
              index == 1 ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                >
                  <path
                    d="M16.6923 4H18.6923C19.2227 4 19.7314 4.21071 20.1065 4.58579C20.4815 4.96086 20.6923 5.46957 20.6923 6V20C20.6923 20.5304 20.4815 21.0391 20.1065 21.4142C19.7314 21.7893 19.2227 22 18.6923 22H6.69226C6.16183 22 5.65312 21.7893 5.27805 21.4142C4.90297 21.0391 4.69226 20.5304 4.69226 20V6C4.69226 5.46957 4.90297 4.96086 5.27805 4.58579C5.65312 4.21071 6.16183 4 6.69226 4H8.69226"
                    stroke="url(#paint0_linear_37_8741)"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M15.6923 2H9.69226C9.13998 2 8.69226 2.44772 8.69226 3V5C8.69226 5.55228 9.13998 6 9.69226 6H15.6923C16.2445 6 16.6923 5.55228 16.6923 5V3C16.6923 2.44772 16.2445 2 15.6923 2Z"
                    stroke="url(#paint1_linear_37_8741)"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12.6923 11H16.6923"
                    stroke="url(#paint2_linear_37_8741)"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12.6923 16H16.6923"
                    stroke="url(#paint3_linear_37_8741)"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8.69226 11H8.70226"
                    stroke="url(#paint4_linear_37_8741)"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8.69226 16H8.70226"
                    stroke="url(#paint5_linear_37_8741)"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_37_8741"
                      x1="12.6923"
                      y1="4"
                      x2="12.6923"
                      y2="22"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#002856" />
                      <stop offset="1" stopColor="#005CA4" />
                    </linearGradient>
                    <linearGradient
                      id="paint1_linear_37_8741"
                      x1="12.6923"
                      y1="2"
                      x2="12.6923"
                      y2="6"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#002856" />
                      <stop offset="1" stopColor="#005CA4" />
                    </linearGradient>
                    <linearGradient
                      id="paint2_linear_37_8741"
                      x1="14.6923"
                      y1="11"
                      x2="14.6923"
                      y2="12"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#002856" />
                      <stop offset="1" stopColor="#005CA4" />
                    </linearGradient>
                    <linearGradient
                      id="paint3_linear_37_8741"
                      x1="14.6923"
                      y1="16"
                      x2="14.6923"
                      y2="17"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#002856" />
                      <stop offset="1" stopColor="#005CA4" />
                    </linearGradient>
                    <linearGradient
                      id="paint4_linear_37_8741"
                      x1="8.69726"
                      y1="11"
                      x2="8.69726"
                      y2="12"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#002856" />
                      <stop offset="1" stopColor="#005CA4" />
                    </linearGradient>
                    <linearGradient
                      id="paint5_linear_37_8741"
                      x1="8.69726"
                      y1="16"
                      x2="8.69726"
                      y2="17"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#002856" />
                      <stop offset="1" stopColor="#005CA4" />
                    </linearGradient>
                  </defs>
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                >
                  <path
                    d="M16.1923 4H18.1923C18.7227 4 19.2314 4.21071 19.6065 4.58579C19.9816 4.96086 20.1923 5.46957 20.1923 6V20C20.1923 20.5304 19.9816 21.0391 19.6065 21.4142C19.2314 21.7893 18.7227 22 18.1923 22H6.19231C5.66187 22 5.15317 21.7893 4.77809 21.4142C4.40302 21.0391 4.19231 20.5304 4.19231 20V6C4.19231 5.46957 4.40302 4.96086 4.77809 4.58579C5.15317 4.21071 5.66187 4 6.19231 4H8.19231"
                    stroke="#666878"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M15.1923 2H9.19231C8.64002 2 8.19231 2.44772 8.19231 3V5C8.19231 5.55228 8.64002 6 9.19231 6H15.1923C15.7446 6 16.1923 5.55228 16.1923 5V3C16.1923 2.44772 15.7446 2 15.1923 2Z"
                    stroke="#666878"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12.1923 11H16.1923"
                    stroke="#666878"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12.1923 16H16.1923"
                    stroke="#666878"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8.19231 11H8.20231"
                    stroke="#666878"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8.19231 16H8.20231"
                    stroke="#666878"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )
            }
          />

          <Menu
            to="/main/home"
            lalel="Home"
            active={index == 0}
            icon={
              index == 0 ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                >
                  <path
                    d="M3.5 9L12.5 2L21.5 9V20C21.5 20.5304 21.2893 21.0391 20.9142 21.4142C20.5391 21.7893 20.0304 22 19.5 22H5.5C4.96957 22 4.46086 21.7893 4.08579 21.4142C3.71071 21.0391 3.5 20.5304 3.5 20V9Z"
                    stroke="url(#paint0_linear_182_11089)"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9.5 22V12H15.5V22"
                    stroke="url(#paint1_linear_182_11089)"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_182_11089"
                      x1="-1.11842"
                      y1="9.17105"
                      x2="24.9003"
                      y2="16.4888"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#0D2853" />
                      <stop offset="1" stopColor="#2656B2" />
                    </linearGradient>
                    <linearGradient
                      id="paint1_linear_182_11089"
                      x1="7.96053"
                      y1="15.5855"
                      x2="17.0016"
                      y2="17.2807"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#0D2853" />
                      <stop offset="1" stopColor="#2656B2" />
                    </linearGradient>
                  </defs>
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M3 9L12 2L21 9V20C21 20.5304 20.7893 21.0391 20.4142 21.4142C20.0391 21.7893 19.5304 22 19 22H5C4.46957 22 3.96086 21.7893 3.58579 21.4142C3.21071 21.0391 3 20.5304 3 20V9Z"
                    stroke="#666878"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9 22V12H15V22"
                    stroke="#666878"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )
            }
          />

          <Menu
            to="/MyCoupon"
            lalel="My coupon"
            active={index == 2}
            icon={
              index == 2 ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                >
                  <path
                    d="M20.5 12V22H4.5V12"
                    stroke="url(#paint0_linear_37_6627)"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M22.5 7H2.5V12H22.5V7Z"
                    stroke="url(#paint1_linear_37_6627)"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12.5 22V7"
                    stroke="url(#paint2_linear_37_6627)"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12.5 7H8C7.33696 7 6.70107 6.73661 6.23223 6.26777C5.76339 5.79893 5.5 5.16304 5.5 4.5C5.5 3.83696 5.76339 3.20107 6.23223 2.73223C6.70107 2.26339 7.33696 2 8 2C11.5 2 12.5 7 12.5 7Z"
                    stroke="url(#paint3_linear_37_6627)"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12.5 7H17C17.663 7 18.2989 6.73661 18.7678 6.26777C19.2366 5.79893 19.5 5.16304 19.5 4.5C19.5 3.83696 19.2366 3.20107 18.7678 2.73223C18.2989 2.26339 17.663 2 17 2C13.5 2 12.5 7 12.5 7Z"
                    stroke="url(#paint4_linear_37_6627)"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_37_6627"
                      x1="12.5"
                      y1="12"
                      x2="12.5"
                      y2="22"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#002856" />
                      <stop offset="1" stopColor="#005CA4" />
                    </linearGradient>
                    <linearGradient
                      id="paint1_linear_37_6627"
                      x1="12.5"
                      y1="7"
                      x2="12.5"
                      y2="12"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#002856" />
                      <stop offset="1" stopColor="#005CA4" />
                    </linearGradient>
                    <linearGradient
                      id="paint2_linear_37_6627"
                      x1="13"
                      y1="7"
                      x2="13"
                      y2="22"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#002856" />
                      <stop offset="1" stopColor="#005CA4" />
                    </linearGradient>
                    <linearGradient
                      id="paint3_linear_37_6627"
                      x1="9"
                      y1="2"
                      x2="9"
                      y2="7"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#002856" />
                      <stop offset="1" stopColor="#005CA4" />
                    </linearGradient>
                    <linearGradient
                      id="paint4_linear_37_6627"
                      x1="16"
                      y1="2"
                      x2="16"
                      y2="7"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#002856" />
                      <stop offset="1" stopColor="#005CA4" />
                    </linearGradient>
                  </defs>
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                >
                  <path
                    d="M20.5 12V22H4.5V12"
                    stroke="#666878"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M22.5 7H2.5V12H22.5V7Z"
                    stroke="#666878"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12.5 22V7"
                    stroke="#666878"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12.5 7H8C7.33696 7 6.70107 6.73661 6.23223 6.26777C5.76339 5.79893 5.5 5.16304 5.5 4.5C5.5 3.83696 5.76339 3.20107 6.23223 2.73223C6.70107 2.26339 7.33696 2 8 2C11.5 2 12.5 7 12.5 7Z"
                    stroke="#666878"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12.5 7H17C17.663 7 18.2989 6.73661 18.7678 6.26777C19.2366 5.79893 19.5 5.16304 19.5 4.5C19.5 3.83696 19.2366 3.20107 18.7678 2.73223C18.2989 2.26339 17.663 2 17 2C13.5 2 12.5 7 12.5 7Z"
                    stroke="#666878"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )
            }
          />
        </Box>
      ) : null}
      <Box
        style={{
          height: "50px",
          width: "100%",
          backgroundColor: "#FFF",
          // bottom: 0,
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
          boxShadow: "4px 0px 5px rgba(0, 0, 0, 0.25)",
        }}
      >
        <Box>Mana.com</Box>
      </Box>
    </Box>
  );
};
export default BottomMenu;
