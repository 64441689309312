// ==============================|| OVERRIDES - INPUT BASE ||============================== //

export default function InputBase() {
  return {
    MuiInputBase: {
      styleOverrides: {
        sizeSmall: {
          fontSize: '0.75rem'
        },
        root: {
          "& input": {
            // border: "1px solid #677080 !important"
          },
          "& input:focus": {
            // boxShadow: "#6770801c 0 0 0 0.2rem !important"
          }
        }
      }
    }
  };
}
