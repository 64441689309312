import { Outlet } from "react-router-dom";

import { Box, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBack from "components/ArrowBack";
// ==============================|| MAIN LAYOUT ||============================== //

const LibraryLayout = (props) => {
  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      <Box
        component="main"
        sx={{
          width: "calc(100% - 260px)",
          flexGrow: 1,
        }}
      >
        <Box
          sx={{
            position: "relative",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box style={{ height: "100%" }}>
            <Box
              style={{
                height: "60px",
                alignItems: "center",
                paddingLeft: 10,
                paddingRight: 10,
                display: "flex",
                backgroundColor: "#FFF",
              }}
            >
              <ArrowBack color="primary" />
              <Box
                style={{
                  width: "calc(100% - 50px)",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <Typography variant="h3">{"Notification"}</Typography>
              </Box>
              <DeleteIcon />
            </Box>
            <Box style={{ height: "calc(100% - 60px)" }}>
              <Outlet />
            </Box>
          </Box>
          {/* <Footer /> */}
        </Box>
      </Box>
    </Box>
  );
};

export default LibraryLayout;
