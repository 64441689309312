import Label from "./Label";
import ButtonUC from "./ButtonUC";
import SecondaryMenu from "./Header/secondarymenu";
import service from "undefined-service-web";
import BottomMenu from "../layout/MainLayout/BottomMenu";
const App = {
  Label: Label,
  Button: ButtonUC,
  service: service,
  SecondaryMenu: SecondaryMenu,
  BottomMenu: BottomMenu,
  color: {
    main: "#0D2853",
    gray: "#9D9D9D",
    black: "#19191B",
    Secondary: "#00AFA2",
  },
  statprivilege: (id) => {
    service.getHttp("frontend/privilege/statprivilege/" + id);
  },
  statservice: (id) => {
    service.getHttp("frontend/service/statservice/" + id);
  },
  statnotification: (id) => {
    service.getHttp("frontend/home/statnotification/" + id);
  },

  engageservice: (id) => {
    service.getHttp("frontend/service/engageservice/" + id);
  },
  engageprivilege: (id) => {
    service.getHttp("frontend/privilege/engageprivilege/" + id);
  },
  servicehistories: (id, unitid, status) => {
    service.getHttp(
      "frontend/service/servicehistories/" + id + "/" + unitid + "/" + status
    );
  },
};
export default App;
