// third-party
import { FETCH_PromotionTrue, FETCH_PromotionFalse } from "./actions";
// initial state
const initialState = {
  isPromotion: true,
};

// ==============================|| SLICE - MENU ||============================== //
const promotion = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PromotionTrue: {
      return { ...state, isPromotion: true };
    }
    case FETCH_PromotionFalse: {
      return { ...state, isPromotion: false };
    }
    default: {
      return { ...state };
    }
  }
};

export default promotion;

export const fetchPromotionTrue = (message) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_PromotionTrue,
      payload: message || "",
    });
  };
};
export const fetchPromotionFalse = (message) => {
  return (dispatch) => {
    dispatch({
      type: FETCH_PromotionFalse,
      payload: message || "",
    });
  };
};

export const PromotionStatus = {
  PromotionTrue: fetchPromotionTrue,
  PromotionFalse: fetchPromotionFalse,
};
