// project import
import Routes from "routes";
import ThemeCustomization from "themes";
import Locales from "components/Locales";
// import RTLLayout from 'components/RTLLayout';
import ScrollTop from "components/ScrollTop";
import Snackbar from "components/@extended/Snackbar";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPromotionFalse,
  fetchPromotionTrue,
} from "./store/reducers/promotion";
// auth provider
import { FirebaseProvider as AuthProvider } from "contexts/FirebaseContext";
import Loading from "components/Loading";
const App = () => {
  const PROMOTION = localStorage.getItem("MANA-PROMOTION");
  const dispatch = useDispatch();
  if (PROMOTION == "false") {
    dispatch(fetchPromotionFalse());
  } else {
    dispatch(fetchPromotionTrue());
  }

  console.log("App " + PROMOTION);
  return (
    <ThemeCustomization>
      {/* <RTLLayout> */}

      <Locales>
        <ScrollTop>
          <AuthProvider>
            <Loading>
              <Routes />
              <Snackbar />
            </Loading>
          </AuthProvider>
        </ScrollTop>
      </Locales>
      {/* </RTLLayout> */}
    </ThemeCustomization>
  );
};

export default App;
