import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux'
const LoaderUC = () => {

    const { loading } = useSelector(({ loader }) => loader)
    return (
        loading ?
            <Box style={{
                position: "absolute",
                height: "100vh", width: "100vw",
                justifyContent: "center"
                , alignItems: "center"
                , alignContent: "center"
                , backgroundColor: "rgba(0,0,0,0.5)"
                , zIndex: 1500
                , top: 0,
                bottom: 0
                , left: 0,
                right: 0
            }}>
                <Box style={{ marginTop: "50vh", marginLeft: "45vw" }}>
                    <CircularProgress />
                </Box>
            </Box> : null
    )
}
export default LoaderUC