import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Box } from "@mui/material";
const ArrowBack = (props) => {
    const { color } = props
    let navigate = useNavigate();
    return (
        <Box style={{ width: "50px" }} onClick={() => {
            navigate(-1);
        }}>
            <ArrowBackIosIcon
                color={color} />
        </Box>
    )
}

ArrowBack.propTypes = {
    color: PropTypes.string
};
export default ArrowBack