// third-party
import { combineReducers } from "redux";

// project import
import menu from "./menu";
import snackbar from "./snackbar";
import loader from "./loader";
import promotion from "./promotion";
import Loading from "./Loading";
import auth from "./auth";
import home from "./home";
import notification from "./notification";
const reducers = combineReducers({
  menu,
  snackbar,
  loader,
  promotion,
  loading: Loading,
  auth: auth,
  home:home,
  notification:notification
});

export default reducers;
