import { notification } from "./actions";
// initial state
const initialState = {
    data: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case notification: {
      return {
        ...state,
        data: action.payload,
      };
    }
    default:
      return state;
  }
};
