import { useState } from "react";
import { Outlet, } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBack from "components/ArrowBack";
// ==============================|| MAIN LAYOUT ||============================== //

const LibraryLayout = (props) => {
  let navigate = useNavigate();
  const [showtitle, setshowtitle] = useState(0)
  const [id, setID] = useState(0);
  const setShowtitle = (e) => {
    setshowtitle(e)
  }
  return (
    <Box sx={{ display: "flex", width: "100%" }}>
     <Box
        component="main"
        sx={{
          width: "calc(100% - 260px)",
          flexGrow: 1,
        }}
      >
        <Box
          sx={{
            position: "relative",
            minHeight: "calc(100vh - 110px)",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box style={{ height: "100%" }}>
            <Outlet context={[setshowtitle, setID]} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LibraryLayout;
